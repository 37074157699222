/*
/ LocalStorage helper functions
*/

type RowDataType = {
  item_name: string | null;
  item_start: string;
  item_end: string;
};

export const EVENTS_KEY = "VIRGO.EVENTS";
export const SELECTED_CATEGORY_KEY = "VIRGO.SELECTED_CATEGORY";

export const getData = (key: string) => {
  const localStore = localStorage.getItem(key);
  return localStore ? JSON.parse(localStore) : [];
};

export const saveData = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const appendEvents = (data: RowDataType) => {
  let existingData = getData(EVENTS_KEY);
  const input = {
    item_name: data.item_name,
    item_start: data.item_start,
    item_end: data.item_end,
  };
  let baseData = existingData ? existingData : [];
  const updatedData = [...baseData, input];
  saveData(EVENTS_KEY, updatedData);
};
