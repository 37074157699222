/*
/ Helper functions for export function
*/

export const convertToTsv = (
  arr: any,
  delimiter: string,
  includeHeaders: boolean
) => {
  const keys = Object.keys(arr[0]);
  const replacer = (_key: any, value: any) => (value === null ? "" : value);
  const processRow = (row: any) =>
    keys.map((key) => JSON.stringify(row[key], replacer)).join(delimiter);
  return [
    ...(includeHeaders ? keys.join(delimiter) : []),
    ...arr.map(processRow),
  ].join("\r\n");
};

export const downloadFile = (fileName: string, data: any) => {
  var link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(data)
  );
  link.setAttribute("download", fileName);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
