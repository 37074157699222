import React, { useState } from "react";
import CardWrapper from "../components/CardWrapper";
import { Link } from "react-router-dom";
import PAGES from "../consts/page_urls";
import { EVENTS_KEY, getData } from "../helpers/localStorageHelper";
import { convertToTsv, downloadFile } from "../helpers/exportHelper";

const Home = (): JSX.Element => {
  const [emptyData, setEmptyData] = useState<boolean>();

  const exportData = () => {
    const data = getData(EVENTS_KEY);
    setEmptyData(data.length === 0);
    if (data.length !== 0) {
      const csv = convertToTsv(data, "\t", false);
      downloadFile(`export_${new Date().toJSON().slice(0, 10)}.tsv`, csv);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <CardWrapper>
        <>
          <Link to={PAGES.NEW_EVENT}>
            <button
              type="button"
              className="block ml-auto mr-auto mb-4 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Új esemény
            </button>
          </Link>
          <button
            type="button"
            className="block ml-auto mr-auto bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
            data-te-ripple-init
            data-te-ripple-color="light"
            onClick={exportData}
          >
            Esemény exportálása
          </button>
          {emptyData && (
            <p className="text-red-500 text-xs italic">
              Nincs még esemény feltöltve, kérjük előbb töltsön fel eseményt!
            </p>
          )}
        </>
      </CardWrapper>
    </div>
  );
};

export default Home;
