import { Dispatch, SetStateAction, createContext, useContext } from "react";

interface ICategoryContext {
  categories: string[];
  setCategories: Dispatch<SetStateAction<string[]>>;
  selectedCategory: string | null;
  setSelectedCategory: Dispatch<SetStateAction<string | null>>;
}

export const CategoryContext = createContext<ICategoryContext>({
  categories: [],
  setCategories: () => {},
  selectedCategory: "",
  setSelectedCategory: () => {},
});

export function useCategory() {
  return useContext(CategoryContext);
}
