import InputMask from "react-input-mask";

const TimeInput = (props: any): JSX.Element => {
  let mask = "éÉÉÉ-hH-nN. óÓ:pP";

  let formatChars = {
    é: "[1-2]",
    É: "[0-9]",
    h: "[0-1]",
    H: "[0-9]",
    n: "[0-3]",
    N: "[1-9]",
    ó: "[0-2]",
    Ó: "[0-9]",
    p: "[0-5]",
    P: "[0-9]",
  };

  let beforeMaskedValueChange = (newState: any) => {
    let { value } = newState;

    let dateTimeParts = value.split(".");
    if (dateTimeParts.length === 2) {
      // datePart
      let dateParts = dateTimeParts[0].split("-");

      let yearPart = dateParts[0];
      let monthPart = dateParts[1];
      let dayPart = dateParts[2];

      // Conditional mask for the 2nd digit of month based on the first digit
      formatChars["H"] = monthPart.startsWith("1") ? "[0-2]" : "[1-9]";
      // Conditional mask for day
      if (!yearPart.includes("_") && !monthPart.includes("_")) {
        // Find last day of the month
        let endOfMonth = new Date(`${yearPart}-01-01`);
        endOfMonth.setMonth(parseInt(monthPart));
        endOfMonth.setDate(0);
        let lastDayOfMonth = endOfMonth.getDate().toString();

        // Set [0-x] dynamically for the first digit based of last day
        formatChars["n"] = `[0-${lastDayOfMonth[0]}]`;

        if (dayPart.startsWith(lastDayOfMonth[0]))
          formatChars["N"] = `[0-${lastDayOfMonth[1]}]`;
        // Limit month's last digit based on last day
        else if (dayPart.startsWith("0"))
          formatChars["N"] = "[1-9]"; // To block 00.
        else formatChars["N"] = "[0-9]"; // To allow days to start with 1 Eg: 10, 12, 15, etc.
      }

      // timePart
      let timeParts = dateTimeParts[1].split(":");
      // Conditional mask for the 2nd digit base on the first digit
      formatChars["Ó"] = timeParts[0].trim().startsWith("2")
        ? "[0-3]"
        : "[0-9]";
    }

    return { value, selection: newState.selection };
  };
  return (
    <InputMask
      placeholder="ÉÉÉÉ-HH-NN. ÓÓ:PP"
      mask={mask}
      value={props.value}
      onChange={props.onChange}
      // @ts-ignore:next-line
      formatChars={formatChars}
      beforeMaskedValueChange={beforeMaskedValueChange}
    ></InputMask>
  );
};

export default TimeInput;
