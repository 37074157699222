import { useContext } from "react";
import CardWrapper from "../components/CardWrapper";
import { Link, useNavigate } from "react-router-dom";
import { CategoryContext } from "../context/category";
import PAGES from "../consts/page_urls";

const NewEvent = (): JSX.Element => {
  const { categories, setSelectedCategory } = useContext(CategoryContext);
  const navigate = useNavigate();

  const addNewItem = (category: string) => {
    setSelectedCategory(category);
    navigate(PAGES.NEW_ITEM);
  };

  const renderCategories = () => {
    return (
      <>
        {categories.map((category: string) => {
          return (
            <button
              key={category}
              type="button"
              className="block ml-auto mr-auto mb-4 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={() => addNewItem(category)}
            >
              {category}
            </button>
          );
        })}
        <Link to="/new-category">
          <button
            type="button"
            className="block ml-auto mr-auto mb-4 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
            data-te-ripple-init
            data-te-ripple-color="light"
          >
            Új kategória
          </button>
        </Link>
      </>
    );
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <CardWrapper title="Új esemény">{renderCategories()}</CardWrapper>
    </div>
  );
};

export default NewEvent;
