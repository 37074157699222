import React, { useContext, useState } from "react";
import CardWrapper from "../components/CardWrapper";
import { CategoryContext } from "../context/category";
import { appendEvents } from "../helpers/localStorageHelper";
import TimeInput from "../components/TimeInput";
import { useNavigate } from "react-router-dom";
import PAGES from "../consts/page_urls";

const NewItem = (): JSX.Element => {
  const { selectedCategory } = useContext(CategoryContext);
  const [startItem, setStartItem] = useState<string>("");
  const [endItem, setEndItem] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const sanityCheckDateTime = (startTime: number, endTime: number) => {
    return endTime < startTime;
  };

  const addItem = () => {
    const startTime = Date.parse(startItem.replace(/\./g, ""));
    const endTime = Date.parse(endItem.replace(/\./g, ""));
    if (isNaN(startTime) || isNaN(endTime)) {
      setErrorMessage("Összes mező kitöltése kötelező!");
    } else if (sanityCheckDateTime(startTime, endTime)) {
      setErrorMessage("A Vége dátum nem lehet hamarabb, mint a Kezdés dátuma!");
    } else {
      appendEvents({
        item_name: selectedCategory,
        item_start: new Date(startTime).toISOString(),
        item_end: new Date(endTime).toISOString(),
      });
      navigate(PAGES.HOME);
    }
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addItem();
  };

  const startItemChanged = (event: any) => {
    setStartItem(event.target.value);
  };

  const endItemChanged = (event: any) => {
    setEndItem(event.target.value);
  };

  const renderFeedback = () => {
    return (
      errorMessage && (
        <p className="text-red-500 text-xs italic">{errorMessage}</p>
      )
    );
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <CardWrapper title={selectedCategory || ""}>
        <form className="w-full max-w-sm" onSubmit={submitForm}>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="start-time"
              >
                Kezdete:
              </label>
            </div>
            <div className="md:w-2/3">
              <TimeInput
                id="start-time"
                className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                onChange={startItemChanged}
                value={startItem}
              ></TimeInput>
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="end-time"
              >
                Vége:
              </label>
            </div>
            <div className="md:w-2/3">
              <TimeInput
                id="end-time"
                className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                onChange={endItemChanged}
                value={endItem}
              ></TimeInput>
            </div>
          </div>
          <button
            type="button"
            className="block ml-auto mr-auto mb-4 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
            data-te-ripple-init
            data-te-ripple-color="light"
            onClick={addItem}
          >
            OK
          </button>
          {renderFeedback()}
        </form>
      </CardWrapper>
    </div>
  );
};

export default NewItem;
