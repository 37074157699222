import React, { useContext, useState } from "react";
import CardWrapper from "../components/CardWrapper";
import { CategoryContext } from "../context/category";
import { useNavigate } from "react-router-dom";
import PAGES from "../consts/page_urls";

const NewCategory = (): JSX.Element => {
  const [categoryName, setCategoryName] = useState<string>("");
  const { categories, setCategories, setSelectedCategory } =
    useContext(CategoryContext);
  const navigate = useNavigate();

  const handleCategoryNameOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setCategoryName(e.target.value);
  };

  const addCategory = () => {
    if (!categories.includes(categoryName)) {
      setCategories([...categories, categoryName]);
    }
    setSelectedCategory(categoryName);
    navigate(PAGES.NEW_ITEM);
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addCategory();
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <CardWrapper title="Új kategória">
        <>
          <form className="w-full max-w-sm" onSubmit={submitForm}>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label
                  className="block font-bold md:text-right mb-1 md:mb-0 pr-4"
                  htmlFor="category-name"
                >
                  Kategória:
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  id="category-name"
                  type="text"
                  onChange={handleCategoryNameOnChange}
                  value={categoryName}
                />
              </div>
            </div>
            <button
              type="button"
              className="block ml-auto mr-auto mb-4 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={addCategory}
            >
              OK
            </button>
          </form>
        </>
      </CardWrapper>
    </div>
  );
};

export default NewCategory;
