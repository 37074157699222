import { ReactNode } from "react";
import { Link } from "react-router-dom";
import PAGES from "../consts/page_urls";

type Props = {
  title?: string;
  children: ReactNode | null;
};

const CardWrapper = ({ title, children }: Props): JSX.Element => {
  return (
    <div className=" block rounded-lg bg-white text-center border">
      <h1 className="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50 text-3xl font-extrabold dark:text-white">
        <Link to={PAGES.HOME}>BABYLOG</Link>
        {title && (
          <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
            {title}
          </small>
        )}
      </h1>
      <div className="p-6">{children && <div>{children}</div>}</div>
    </div>
  );
};

export default CardWrapper;
