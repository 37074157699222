import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import PAGES from "./consts/page_urls";
import Home from "./pages/Home";
import NewEvent from "./pages/NewEvent";
import NewCategory from "./pages/NewCategory";
import { CategoryContext } from "./context/category";
import NewItem from "./pages/NewItem";
import {
  SELECTED_CATEGORY_KEY,
  getData,
  saveData,
} from "./helpers/localStorageHelper";

const App = (): JSX.Element => {
  const [categories, setCategories] = useState<string[]>([
    "Alvás",
    "Evés",
    "Fürdés",
  ]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    const storedCategory = getData(SELECTED_CATEGORY_KEY);
    if (storedCategory) {
      setSelectedCategory(storedCategory);
    }
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      saveData(SELECTED_CATEGORY_KEY, selectedCategory);
    }
  }, [selectedCategory]);

  return (
    <div>
      <CategoryContext.Provider
        value={{
          categories,
          setCategories,
          selectedCategory,
          setSelectedCategory,
        }}
      >
        <Routes>
          <Route path={PAGES.HOME} element={<Home />} />
          <Route path={PAGES.NEW_EVENT} element={<NewEvent />} />
          <Route path={PAGES.NEW_CATEGORY} element={<NewCategory />} />
          <Route path={PAGES.NEW_ITEM} element={<NewItem />} />
        </Routes>
      </CategoryContext.Provider>
    </div>
  );
};

export default App;
